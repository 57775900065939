<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a></p>
                </div>
            </div>
        </div>
     



        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>修改<em>密码</em></span>
                    </div>
                    <el-form v-if="!editshowPass" label-width="80px" :model="sendcode">
                        <el-form-item label="手机号">
                            <el-input v-model="sendcode.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码">
                            <div style="display:flex;align-items: center;">
                                <el-input type="password" v-model="sendcode.code" style="margin-right:10px"></el-input>
                                <el-button v-if="verifyShow" @click="goSend"  type="primary" >发送验证码</el-button>
                                <el-button v-else >{{verifyTip+'秒后重获'}}</el-button>
                            </div>
                          
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onsendSubmit">下一步</el-button>
                        </el-form-item>
                    </el-form>
                    <div v-if="editshowPass" style="margin:20px">
                        <div style="display:flex;align-items:center;margin-bottom:20px">
                            <div :class="editpayshowPass == 1 ? 'showPass' : '' " @click="editpayshowPass = 1" style="margin-right:20px">修改登录密码</div>
                            <div :class="editpayshowPass == 2 ? 'showPass' : '' " @click="editpayshowPass = 2">修改支付密码</div>
                        </div>
                        <el-form v-if="editpayshowPass == 1" label-width="80px" :model="resetPassword">
                        <el-form-item label="登录密码">
                            <el-input type="password" v-model="resetPassword.newPwd" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码">
                            <el-input type="password" v-model="resetPassword.confirmPwd" show-password></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">保存</el-button>
                        </el-form-item>
                    </el-form>
                    <el-form v-if="editpayshowPass == 2" label-width="80px" :model="payPassword">
                        <el-form-item label="支付密码">
                            <el-input type="password" v-model="payPassword.newPwd" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码">
                            <el-input type="password" v-model="payPassword.confirmPwd" show-password></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onpaySubmit">保存</el-button>
                        </el-form-item>
                    </el-form>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
    import ftCom from '../../components/footer'
    import nhCom from "../../components/navheader";
    import hdImg from "../../components/headerImg";
    import {UserInfo} from "../../components/User/UserInfo";
    import {shoppingMall} from "../../components/shoppingMall/shoppingMall";
    export default {
        name: "resetPassword",
        components: {
            ftCom,
            nhCom,
            hdImg
        },
        data() {
            var user=new UserInfo(this.TokenClient, this.Services.Authorization)
            var UserDistributorltem = new shoppingMall(this.TokenClient, this.Services.Authorization);
            return {
                userDomain:user,
                labelPosition: 'right',
                resetPassword: {
                    newPwd:'',
                    confirmPwd:''
                },
                payPassword:{
                    newPwd:'',
                    confirmPwd:''
                },
                sendcode:{
                    phone:'',
                    code:''
                },
                verifyShow:true,
                verifyTip: 60,
                userInfo: this.$store.state.localData.userInfo,
                UserDistributorMain:UserDistributorltem,
                editshowPass:false,
                editpayshowPass:1
            };
        },
        methods: {
            goSend(){
                var _this = this
				if (_this.sendcode.phone == _this.userInfo.phone) {
					_this.timedown(_this.verifyTip);
                    _this.UserDistributorMain.getSendVerifyCode(_this.userInfo.phone,function (data) {
                        _this.UserBalance = data.data
                        _this.$message({
                            type: 'success',
                            message: '发送成功!'
                        });
                    },
                    function (err) {
                        console.log(err)
                        _this.$message.error(JSON.parse(err.msg).msg);
                    }
                    )

				} else {
                    _this.$message.error('请检查您输入的手机号');
				}
			},
            onsendSubmit(){
                var _this = this
				if (_this.sendcode.phone == _this.userInfo.phone) {
                    if (_this.sendcode.code) {
                          _this.UserDistributorMain.getIsVerify(_this.userInfo.phone,_this.sendcode.code,function (data) {
                        _this.editshowPass = true
                    },
                    function (err) {
                        _this.$message.error(JSON.parse(err.msg).msg);
                    }
                    )
                    }
                    else {
					_this.$message.error('请检查您输入的验证码');
				    }
                  
				} else {
					_this.$message.error('请检查您输入的手机号');
				}
            },
            timedown(num){
				let that = this;
				if(num == 0){
					that.verifyShow = true;				// 是否显示获取验证码
					return clearTimeout();
				}else{
					that.verifyShow = false;			// 是否显示获取验证码
					setTimeout(function() {  
						that.verifyTip = num-1
						that.timedown(num-1);  
					}, 1000);//定时每秒减一  
				}
			},
            
            onSubmit() {
                var _this = this;
                var item=_this.resetPassword;
                if (item.newPwd != item.confirmPwd) {
                    _this.$message.error('俩次密码输入不一致，请检查密码！');
                    return false;
                }
                _this.UserDistributorMain.getSetUserPassword(_this.userInfo.id, item.newPwd,function (data) {
                    _this.$alert('密码修改成功，将返回登录页面，请用新密码重新登录！', {
                        confirmButtonText: '确定',
                        callback: action => {
                            _this.$router.push({
                                path: "/Login",
                                query: {}
                            });
                        }
                    });
                },function (error) {
                    _this.$message.error('修改失败。');
                })
            },
            onpaySubmit(){
                var _this = this;
                var item=_this.payPassword;
                if (item.newPwd != item.confirmPwd) {
                    _this.$message.error('俩次密码输入不一致，请检查密码！');
                    return false;
                }
                let params = {
						payPassword: item.newPwd
				}
                _this.UserDistributorMain.getPayPassword(params,function (data) {
                    _this.$message({
                            type: 'success',
                            message: '支付密码设置成功'
                        });
                },function (error) {
                    _this.$message.error('修改失败');
                })
                
            }
        }
    }
</script>

<style scoped>
    @import "../../assets/css/footer.css";

    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg") center no-repeat;
    }

    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
    /**/
    .personal-content{
        background: #f7f7f7;
        padding: 40px 0;
    }
    .personal-box{
        width: 1200px;
        margin: 0 auto;
    }
    .personal-left{
        float: left;
    }
    .personal-right{
        float: right;
        width: 900px;
        background: #FFFFFF;
        box-sizing: border-box;
    }
    .personal-right-tit{
        border-bottom: 1px solid #e5e5e5;
        padding: 20px;
    }
    .personal-right-tit .iconfont{
        color: #04a290;
        font-size: 24px;
        margin-right: 5px;
    }
    .personal-right-tit span{
        display: inline-block;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
    }
    .personal-right-tit span em{
        font-style: normal;
        color: #04a290;
    }
    .personal-right>form{
        padding: 30px 15px;
        width: 460px;
    }
    .showPass {
      color: #00A290;  
    }
</style>
<style>
    .personal-center{
        display: none;
    }
</style>
